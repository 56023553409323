import { Component, OnInit } from '@angular/core';
import { ConsentimientoService } from './services/consentimiento/consentimiento.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'telepagos-consentimiento-web';

  step: Steps = Steps.LOGIN;
  hasFailed : boolean = false;
  hasFailedMessage : string = "";

  constructor(
    private consentimientoService: ConsentimientoService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
         this.route.queryParamMap.subscribe(
           (params) => {
             let uuid = params.get('uuid') ;
             if(!uuid){
               this.hasFailed = true;
               return;
             }
             this.consentimientoService.setUuid(uuid);
           }
         );
      }
    })

    this.consentimientoService.tokenExpired$.subscribe(()=>{
      this.hasFailed = true;
    })
  }

  onNext() {
    this.step++
  }

  onFail(message: string) {
    this.hasFailed = true;
    this.hasFailedMessage = message;
  }

  get steps() {
    return Steps
  }
}

enum Steps {
  LOGIN = 0,
  TWO_FACTOR = 1,
  CONNECT = 2,
}
