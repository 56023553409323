import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cuenta } from 'src/app/interfaces/cuentas.interface';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { ApiCommonResponse } from 'src/app/interfaces/responses/common/ApiResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsentimientoService {

  private url = environment.url;
  private uuid: string = "";
  private token: string = "";

  private tokenExpiredSubject: Subject<void>;
  public tokenExpired$: Observable<void>;

  private expirationTimer$: Observable<number>;
  private expirationTimerSubscription: Subscription;

  constructor(
    private http: HttpClient
  ) {
    this.tokenExpiredSubject = new Subject();
    this.tokenExpired$ = this.tokenExpiredSubject.asObservable()
  }

  public login(data: { email: string, password: string }) {
    let _data = { ...data, uuid: this.uuid }
    return this.http.post<ApiCommonResponse & { token: string, expires_in: number }>(this.url + 'consentimiento/web/login', _data, { headers: { Accept: 'application/json' } })
  }
  public activarSegundoFactor(data: { codigo: string }) {
    let _data = { ...data, uuid: this.uuid }
    return this.http.post<ApiCommonResponse>(this.url + 'consentimiento/web/activar_segundo_factor', _data, { headers: this.getHeaders() })
  }
  public cuentas() {
    let _data = { uuid: this.uuid }
    return this.http.get<ApiCommonResponse & { cuentas: Cuenta[], nombre_entidad: string, razon_social: string }>(this.url + 'consentimiento/web/cuentas', { params: _data, headers: this.getHeaders() })
  }
  public confirmar(data: { cuentas: string[] }) {
    let _data = { ...data, uuid: this.uuid }
    return this.http.post<ApiCommonResponse & { redirect_to: string }>(this.url + 'consentimiento/web/confirmar_autorizacion', _data, { headers: this.getHeaders() })
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    if (this.token) {
      headers = headers.set('Authorization', 'Bearer ' + this.token)
    }
    return headers;
  }

  public setUuid(value: string) {
    this.uuid = value;
  }
  public setToken(value: string) {
    this.token = value;
  }
  public setExpirationTime(value: number) {
    this.expirationTimer$ = timer(value * 1000);
    this.expirationTimerSubscription = this.expirationTimer$.subscribe(() => {
      this.tokenExpiredSubject.next();
      this.expirationTimerSubscription.unsubscribe();
    })
  }

}


