import { Component, OnInit, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { emailPattern } from 'src/app/helpers/patterns';
import { ConsentimientoService } from 'src/app/services/consentimiento/consentimiento.service';
import { LoadersService } from 'src/app/services/loaders/loaders.service';
import { MensajeAlertService } from 'src/app/services/mensaje-alert/mensaje-alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Output() next = new EventEmitter<void>();
  @Output() error = new EventEmitter<string>();

  @ViewChildren(IonInput) inputs: QueryList<IonInput>;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private consentimientoService: ConsentimientoService,
    private loadersService: LoadersService,
    private mensajeAlertService: MensajeAlertService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      password: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.inputs.first.setFocus();
    // }, 500);
  }

  login() {
    if (this.form.valid) {
      this.loadersService.presentLoadingWithOptions().then(() => {
        this.consentimientoService.login({
          email: this.form.get('email').value,
          password: this.form.get('password').value,
        }).subscribe(
          (res) => {
            /*mockup*/
            // this.consentimientoService.setToken("cxvxcvxcvxcvxcvxcv");
            // this.consentimientoService.setExpirationTime(10);
            // this.loadersService.dimissLoading();
            // this.next.emit();
            // return;
            /*end mockup*/
            if (res.status == 'ok') {
              this.consentimientoService.setToken(res.token);
              this.consentimientoService.setExpirationTime(res.expires_in);
              this.loadersService.dimissLoading();
              this.next.emit();
            } else {
              this.loadersService.dimissLoading();
              this.mensajeAlertService.alertSimple(res.mensaje_error);
            }
          },
          (err) => {
            console.error(err);
            this.loadersService.dimissLoading();
            this.error.emit('');
          }
        )
      })
    }

  }

  get emailCtrl() { return this.form.get('email') }
  get passowrdCtrl() { return this.form.get('password') }

}
