<ng-container *ngIf="!hasFailed">
  <app-login *ngIf="step == steps.LOGIN" (next)="onNext()" (error)="onFail($event)"></app-login>
  <app-two-factor *ngIf="step == steps.TWO_FACTOR" (next)="onNext()" (error)="onFail($event)"></app-two-factor>
  <app-connect *ngIf="step == steps.CONNECT" (error)="onFail($event)"></app-connect>
</ng-container>

<ng-container *ngIf="hasFailed">
  <!-- <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh;">
    <div style="text-align: center;">
      <ion-icon name="close-circle-outline" color="dark" style="zoom:4.0;"></ion-icon>
      <p>El proceso ha fallado</p>
    </div>
  </div> -->

  <div class="content">
    <img class="icon" src="../assets/svg/close-circle-outline.svg">
    <p>
      <span class="t1">
        {{ hasFailedMessage || 'El proceso ha fallado' }}
      </span>
    </p>
  </div>

</ng-container>
