import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { ConsentimientoService } from 'src/app/services/consentimiento/consentimiento.service';
import { LoadersService } from 'src/app/services/loaders/loaders.service';
import { MensajeAlertService } from 'src/app/services/mensaje-alert/mensaje-alert.service';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements OnInit, AfterViewInit {

  @Output() next = new EventEmitter<void>();
  @Output() error = new EventEmitter<string>();

  @ViewChildren(IonInput) inputs: QueryList<IonInput>;

  form: FormGroup;

  readonly codeLenght = 6;

  constructor(
    private fb: FormBuilder,
    private consentimientoService: ConsentimientoService,
    private loadersService: LoadersService,
    private mensajeAlertService: MensajeAlertService
  ) {
    let inputs: FormControl[] = [];

    for (let i = 0; i < this.codeLenght; i++) {
      inputs.push(this.fb.control('', [Validators.required]))
    }

    this.form = this.fb.group(
      {
        codigo: this.fb.array(inputs)
      }
    )
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.inputs.first.setFocus();
    // }, 500)
  }

  validar() {
    if (this.form.valid) {
      let codigo = (this.codigoFormArray.value as string[]).join("");
      this.loadersService.presentLoadingWithOptions().then(() => {
        this.consentimientoService.activarSegundoFactor({
          codigo: codigo
        }).subscribe(
          (res) => {
            /*mockup*/
            // this.loadersService.dimissLoading();
            // this.next.emit();
            // return
            /*end mockup*/
            if (res.status == 'ok') {
              this.loadersService.dimissLoading();
              this.next.emit();
            } else {
              this.loadersService.dimissLoading();
              this.mensajeAlertService.alertSimple(res.mensaje_error);
            }
          },
          (err) => {
            console.error(err);
            this.loadersService.dimissLoading();
            this.error.emit('');
          }
        )
      });

    }
  }

  proximoInput(actual: number, event: any) {
    if (this.codigoFormArray.at(actual).value) {
      if (actual == (this.codeLenght - 1)) {
        this.inputs.last.getInputElement().then(i => i.blur())
        return;
      }
      this.inputs.toArray()[actual + 1].setFocus();
    } else if (!event && actual > 0) {
      this.inputs.toArray()[actual - 1].setFocus();
    }
  }

  onPaste(actual: number, event: ClipboardEvent) {
    let clipboardData = event.clipboardData.getData('text').split('');
    //mientras actual sea menor a los inputs
    while(actual < this.inputs.length){
      let value = clipboardData.splice(0, 1)[0]
      //seteo valor en input
      this.codigoFormArray.at(actual).setValue(value);
      ++actual;
    }
  }

  get codigoFormArray(): FormArray { return (this.form.get('codigo') as FormArray) }

}
