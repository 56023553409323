<ion-header class="ion-no-border">
  <ion-toolbar class="toolbarPrimary">
    <div class="ion-text-center">
      <img class="logo-telepagos" src="../../assets/imagenes/logo-blanco.png">
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="bg-white">
  <div class="bg-telepagos-primary" style="height: 2%;"></div>
  <ion-grid class="grid-registro">
    <ion-row class="row-height-060 justify-center">
    <!-- <ion-row class="row-recuperar-acceso-email justify-center"> -->
      <ion-col size="11">
        <h3 class="titulo-slide">Ingresá con el Código que enviamos a tu Correo</h3>
      </ion-col>
      <ion-col size="11">

          <ion-label class="label-input" position="floating" color="medium">Código:</ion-label>

        <form [formGroup]="form">
          <ion-row class="row-codigo" formArrayName="codigo">
            <ion-col size="2" *ngFor="let item of codigoFormArray.controls; let i=index ">
              <ion-item class="item">
                <ion-input [name]="'codigo' + i" [formControlName]="i" maxlength="1" type="text" pattern="\d*"
                  (ionChange)="proximoInput(i,$event)" class="ion-text-center" (paste)="onPaste(i,$event)" required></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </form>
      </ion-col>
    </ion-row>
    <ion-row class="justify-center width-100">
      <!-- <ion-row class="row-bottom justify-center width-100"> -->
      <ion-col size="11">
        <ion-button [disabled]="!form.valid" class="btn-primario" expand="block"
          (click)="validar()">VALIDAR</ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>
