import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Cuenta } from 'src/app/interfaces/cuentas.interface';
import { ConsentimientoService } from 'src/app/services/consentimiento/consentimiento.service';
import { LoadersService } from 'src/app/services/loaders/loaders.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {

  @Output() error = new EventEmitter<string>();

  hostName: string = "";
  ownerName: string = ""

  // cuentas : { cvu : string , id : number , checked : boolean }[] = [
  //   {cvu: "0000045100000000014827", id: 1, checked: false}
  // ]

  cuentas: Cuenta[] = [];

  constructor(
    private consentimientoService: ConsentimientoService,
    private loadersService: LoadersService
  ) { }

  ngOnInit(): void {
    this.loadersService.presentLoadingWithOptions().then(() => {
      this.consentimientoService.cuentas().subscribe(
        (res) => {
          /*mockup*/
          // res.cuentas = [
          //   {
          //     cvu: "0000045100000000014827",
          //     app_name: "TelePagos",
          //     logo: "../../assets/imagenes/logo-avatar.png",
          //     checked: false
          //   },
          //   {
          //     cvu: "0000045100000000014828",
          //     app_name: "TelePagos",
          //     logo: "../../assets/imagenes/logo-avatar.png",
          //     checked: false
          //   },
          //   {
          //     cvu: "0000045100000000014829",
          //     app_name: "TelePagos",
          //     logo: "../../assets/imagenes/logo-avatar.png",
          //     checked: false
          //   },
          // ]
          // this.cuentas = res.cuentas;
          // this.loadersService.dimissLoading();
          // return;
          /*end mockup*/
          if (res.status == 'ok') {
            this.cuentas = res.cuentas.map((c) => { return { ...c, checked: false } });
            this.ownerName = res.razon_social;
            this.hostName = res.nombre_entidad;
            this.loadersService.dimissLoading();
          } else {
            this.loadersService.dimissLoading();
            this.error.emit(res.mensaje_error);
          }
        },
        (err) => {
          console.error(err);
          this.loadersService.dimissLoading();
          this.error.emit('');
        }
      )
    })
  }

  conectar() {
    if (this.esValido) {
      this.loadersService.presentLoadingWithOptions().then(() => {
        let cuentas = this.cuentas.map(c => c.cvu);
        this.consentimientoService.confirmar({ cuentas }).subscribe(
          (res) => {
            /*mockup*/
            // let link = document.createElement("a");
            // link.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
            // link.click();
            // return;
            /*end mockup*/
            if(res.status == 'ok'){
              let link = document.createElement("a");
              link.href = res.redirect_to;
              link.click();
            }else {
              this.loadersService.dimissLoading();
              this.error.emit(res.mensaje_error);
            }
          },
          (err) => {
            console.error(err);
            this.loadersService.dimissLoading();
            this.error.emit('');
          }
        )
      });
    }
  }

  get esValido() { return this.cuentas.map(c => c.checked).includes(true) }

}
