import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MensajeAlertService {

  bandera: boolean = false;
  modalAbierto: boolean = false;

  constructor(
    public alertController: AlertController
  ) { }

  async alertSimple(titulo: string = "", mensaje: string = "") {
    const alert = await this.alertController.create({
      cssClass: 'alert-css',
      header: titulo,
      message: mensaje,
      animated: true,
      buttons: ['OK']
    });

    await alert.present();
  }

  async alertCompuesto(titulo: string = "", mensaje: string = "", botonUno: string = "Cancelar", botonDos: string = "Aceptar") {
    const alert = await this.alertController.create({
      cssClass: 'alert-css',
      header: titulo,
      message: mensaje,
      animated: true,
      backdropDismiss: false,
      buttons: [
        {
          text: botonUno,
          cssClass: 'alert-button-role-cancel',
          handler: () => {
            this.bandera = false;
          }
        }, {
          text: botonDos,
          handler: () => {
            this.bandera = true;
          }
        }
      ]
    });
    await alert.present();
    let resultadoDismiss = await alert.onDidDismiss().then(() => { return this.bandera });
    return resultadoDismiss;
  }

  async alertImg(titulo: string = "", imgSrc: string = "") {
    const alert = await this.alertController.create({
      cssClass: 'alert-img-css',
      header: titulo,
      message: `<img src="${imgSrc}">`,
      animated: true,
      buttons: [{
        text: '\u2715'
      }]
    });

    await alert.present();
  }

  async mensajeModal(mensaje: string, header?: string) {
    const title = (header) ? header : 'Ups!';
    if (!this.modalAbierto) {
      this.modalAbierto = true;
      const alert = await this.alertController.create({
        header: title,
        message: mensaje,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.modalAbierto = false;
            }
          },
        ],
      });
      await alert.present();
    }
  }

  cerrarModal() {
    this.alertController.dismiss();
  }
}
