<ion-header class="ion-no-border">
  <ion-toolbar class="toolbarPrimary">
    <!-- <ion-buttons slot="start">
      <ion-button class="backbutton-custom">
        <ion-icon slot="start" name="arrow-back-outline" class="backbutton-custom-icon"></ion-icon>
      </ion-button>
    </ion-buttons> -->
    <ion-title>
      <div class="ion-text-center">
        <img class="logo-telepagos" src="../../assets/imagenes/logo-blanco.png">
      </div>
    </ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button class="hidden">
        <img alt="logo" id="header_logo" height="25" src="../../../../../assets/imagenes/iconos-filtros.png">
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>
<ion-content class="bg-white">
  <div class="bg-telepagos-primary" style="height: 2%;"></div>
  <ion-grid class="ion-page">
    <ion-row class="row-height-060 justify-center">
      <ion-col size="11">
        <h3 class="titulo-slide">Ingresá en tu Cuenta de TelePagos</h3>
      </ion-col>
      <ion-col size="11">
        <form (ngSubmit)="login()" [formGroup]="form">
          <div class="select-list" style="margin-bottom: 1rem;">
            <ion-label class="label-input">Email:</ion-label>
            <ion-input style="--border-radius: 2rem;" class="input" name="email" formControlName="email" type="email" autofocus="true"
              errorText="Invalid email" required>
            </ion-input>
            <div class="text-danger" *ngIf="(!emailCtrl.valid && emailCtrl.touched)">
              <small *ngIf="emailCtrl.errors.required">* Email requerido.</small>
              <small *ngIf="emailCtrl.errors.pattern">* Formato de email inválido. </small>
            </div>
          </div>
          <div class="select-list">
            <ion-label class="label-input">Contraseña:</ion-label>
            <ion-input style="--border-radius: 2rem;" class="input" name="email" formControlName="password"
              type="password" required>
            </ion-input>
            <div class="text-danger" *ngIf="(!passowrdCtrl.valid && passowrdCtrl.touched)">
              <small *ngIf="passowrdCtrl.errors.required">* Contraseña requerida.</small>
            </div>
          </div>
        </form>
      </ion-col>
      <ion-col size="11">
        <ion-button [disabled]="!form.valid" class="btn-primario" expand="block" (click)="login()">
          CONTINUAR</ion-button>
      </ion-col>
    </ion-row>


  </ion-grid>
</ion-content>
