<ion-header class="ion-no-border">
  <ion-toolbar class="toolbarPrimary">
    <ion-title>
      <div class="ion-text-center">
        <img class="logo-telepagos" src="../../assets/imagenes/logo-blanco.png">
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="bg-white">
  <div class="bg-telepagos-primary" style="height: 2%;"></div>
  <ion-grid class="ion-page">
    <ion-row class="row-height-060 justify-center">
      <ion-col size="11">
        <h3 class="titulo-slide">Autorizar a {{ hostName }} que conecte con tu Cuenta de TelePagos</h3>
      </ion-col>
      <ion-col size="11">
        <!-- <form (ngSubmit)="login()" [formGroup]="form">
          <div class="select-list" style="margin-bottom: 1rem;">
            <ion-label class="label-input">Email:</ion-label>
            <ion-input style="--border-radius: 2rem;" class="input" name="email" formControlName="email" type="email"
              required>
            </ion-input>
          </div>
          <div class="select-list">
            <ion-label class="label-input">Contraseña:</ion-label>
            <ion-input style="--border-radius: 2rem;" class="input" name="email" formControlName="password"
              type="password" required>
            </ion-input>
          </div>
          <input type="submit" class="hidden">
        </form> -->

        <ion-label class="label-input" position="floating" color="medium">Cuentas de {{ ownerName }}:</ion-label>

        <ion-list>
          <ion-item *ngFor="let cuenta of cuentas" lines="none">

            <!-- <ion-thumbnail slot="start" >
              <img  src="../../assets/imagenes/logo-check.png" width="32" />
            </ion-thumbnail> -->

            <ion-avatar>
              <img alt="Silhouette of a person's head" [src]="cuenta.logo" />
            </ion-avatar>

            <ion-label style="margin-left: 1rem;">

              <h3>{{ cuenta.app_name }}</h3>

              <small>
                <span><strong>CVU: </strong>{{cuenta.cvu}}</span>
              </small>

            </ion-label>

            <ion-toggle slot="end" class="toggle-primario-list"
            [(ngModel)]="cuenta.checked"></ion-toggle>
            <!-- <ion-toggle class="toggle-primario-list" *ngIf="cargaCompleta"
            [(ngModel)]="loginService.preferencias.email_envio" (ngModelChange)="actualizaPreferencias()"></ion-toggle> -->
          </ion-item>

        </ion-list>


      </ion-col>
      <ion-col size="11">
        <ion-button [disabled]="!esValido" class="btn-primario" expand="block" (click)="conectar()">
          CONECTAR</ion-button>
      </ion-col>
    </ion-row>


  </ion-grid>
</ion-content>
